import React from "react"
import { Link } from "gatsby"

import Layout from "src/components/layout"
import SEO from "src/components/seo"
import PageTitle from "src/components/page-title"

import cohortAnalysis from "src/images/cohort-analysis/splash_image.png"
import userOnboarding from "src/images/index/better-onboarding.jpg"
import tutorialSoftware from "src/images/tutorial-software/il_header_tutorials.svg"
import softwareDocumentation from "src/images/help-documentation/help-documentation-hero.jpg"
import digitalAdoption from "src/images/salesforce-training/digital-adoption-platform.svg"
import CacFormula from "src/images/customer-acquisition-cost/customer-acquisition-cost-formula.png"
import growthCalculator from "src/images/growth-ceiling-calculator.png"

function IndexPage() {
  return (
    <Layout>
      <SEO title="SaaS Resources" />
      <PageTitle
        preheader="Guides and Explainers"
        title="SaaS Resources"
        subtitle="Dip into our free resources for starting, growing and winning in SaaS business."
      ></PageTitle>

      <div class="pb-24">
        <ul class="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
          <li class="col-span-1 flex flex-col text-center bg-white rounded-lg shadow divide-y divide-gray-200">
            <div class="flex-1 flex flex-col p-8">
              <img
                class="w-full flex-shrink-0 mx-auto"
                src={cohortAnalysis}
                alt=""
              />
              <h3 class="mt-6 text-gray-900 text-sm font-medium">
                Cohort Analysis
              </h3>
              <dl class="mt-1 flex-grow flex flex-col justify-between">
                <dt class="sr-only">Title</dt>
                <dd class="text-gray-500 text-sm">
                  Our comprehensive guide to cohort analysis for SaaS business.
                </dd>
              </dl>
            </div>
            <div>
              <div class="-mt-px flex divide-x divide-gray-200">
                <div class="w-0 flex-1 flex">
                  <Link
                    to="/cohort-analysis/"
                    class="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500"
                  >
                    <span class="ml-3">Read</span>
                  </Link>
                </div>
              </div>
            </div>
          </li>

          <li class="col-span-1 flex flex-col text-center bg-white rounded-lg shadow divide-y divide-gray-200">
            <div class="flex-1 flex flex-col p-8">
              <img
                class="w-full flex-shrink-0 mx-auto"
                src={userOnboarding}
                alt=""
              />
              <h3 class="mt-6 text-gray-900 text-sm font-medium">
                User Onboarding Guide
              </h3>
              <dl class="mt-1 flex-grow flex flex-col justify-between">
                <dt class="sr-only">Title</dt>
                <dd class="text-gray-500 text-sm">
                  Our simple how-to guide to user onboarding strategy.
                </dd>
              </dl>
            </div>
            <div>
              <div class="-mt-px flex divide-x divide-gray-200">
                <div class="w-0 flex-1 flex">
                  <a
                    href="/user-onboarding-software/"
                    class="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500"
                  >
                    <span class="ml-3">Read</span>
                  </a>
                </div>
              </div>
            </div>
          </li>

          <li class="col-span-1 flex flex-col text-center bg-white rounded-lg shadow divide-y divide-gray-200">
            <div class="flex-1 flex flex-col p-8">
              <img
                class="w-full flex-shrink-0 mx-auto"
                src={growthCalculator}
                alt=""
              />
              <h3 class="mt-6 text-gray-900 text-sm font-medium">
                Growth Ceiling Calculator
              </h3>
              <dl class="mt-1 flex-grow flex flex-col justify-between">
                <dt class="sr-only">Title</dt>
                <dd class="text-gray-500 text-sm">
                  Figure out when your SaaS business will stop growing, based on
                  your current metrics.
                </dd>
              </dl>
            </div>
            <div>
              <div class="-mt-px flex divide-x divide-gray-200">
                <div class="w-0 flex-1 flex">
                  <a
                    href="/saas-resources/growth-ceiling-calculator/"
                    class="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500"
                  >
                    <span class="ml-3">Try</span>
                  </a>
                </div>
              </div>
            </div>
          </li>

          <li class="col-span-1 flex flex-col text-center bg-white rounded-lg shadow divide-y divide-gray-200">
            <div class="flex-1 flex flex-col p-8">
              <img
                class="w-full flex-shrink-0 mx-auto"
                src={CacFormula}
                alt=""
              />
              <h3 class="mt-6 text-gray-900 text-sm font-medium">
                Customer Acquisition Cost Calculator
              </h3>
              <dl class="mt-1 flex-grow flex flex-col justify-between">
                <dt class="sr-only">Title</dt>
                <dd class="text-gray-500 text-sm">
                  Use this free tool to find out how much it costs you to get a
                  new customer.
                </dd>
              </dl>
            </div>
            <div>
              <div class="-mt-px flex divide-x divide-gray-200">
                <div class="w-0 flex-1 flex">
                  <a
                    href="/saas-resources/customer-acquisition-cost-calculator/"
                    class="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500"
                  >
                    <span class="ml-3">Try</span>
                  </a>
                </div>
              </div>
            </div>
          </li>

          <li class="col-span-1 flex flex-col text-center bg-white rounded-lg shadow divide-y divide-gray-200">
            <div class="flex-1 flex flex-col p-8">
              <img
                class="w-full flex-shrink-0 mx-auto"
                src={tutorialSoftware}
                alt=""
              />
              <h3 class="mt-6 text-gray-900 text-sm font-medium">
                Tutorial Software: Expert Selection Guide
              </h3>
              <dl class="mt-1 flex-grow flex flex-col justify-between">
                <dt class="sr-only">Title</dt>
                <dd class="text-gray-500 text-sm">
                  Read our quick guide on how to pick tutorial software for your
                  business.
                </dd>
              </dl>
            </div>
            <div>
              <div class="-mt-px flex divide-x divide-gray-200">
                <div class="w-0 flex-1 flex">
                  <a
                    href="/tutorial-software/"
                    class="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500"
                  >
                    <span class="ml-3">Read</span>
                  </a>
                </div>
              </div>
            </div>
          </li>

          <li class="col-span-1 flex flex-col text-center bg-white rounded-lg shadow divide-y divide-gray-200">
            <div class="flex-1 flex flex-col p-8">
              <img
                class="w-full flex-shrink-0 mx-auto"
                src={softwareDocumentation}
                alt=""
              />
              <h3 class="mt-6 text-gray-900 text-sm font-medium">
                Software Documentation - Expert Guide
              </h3>
              <dl class="mt-1 flex-grow flex flex-col justify-between">
                <dt class="sr-only">Title</dt>
                <dd class="text-gray-500 text-sm">
                  Why software documentation can make or break a product launch,
                  and how to get it right.
                </dd>
              </dl>
            </div>
            <div>
              <div class="-mt-px flex divide-x divide-gray-200">
                <div class="w-0 flex-1 flex">
                  <a
                    href="/software-documentation/"
                    class="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500"
                  >
                    <span class="ml-3">Read</span>
                  </a>
                </div>
              </div>
            </div>
          </li>


          <li class="col-span-1 flex flex-col text-center bg-white rounded-lg shadow divide-y divide-gray-200">
            <div class="flex-1 flex flex-col p-8">
              <img
                class="w-full flex-shrink-0 mx-auto"
                src={digitalAdoption}
                alt=""
              />
              <h3 class="mt-6 text-gray-900 text-sm font-medium">
                The Nickelled Guide to Digital Adoption Platforms
              </h3>
              <dl class="mt-1 flex-grow flex flex-col justify-between">
                <dt class="sr-only">Title</dt>
                <dd class="text-gray-500 text-sm">
                  Understanding the importance of successful digital adoption and bringing the workforce along with ever-changing digital processes.
                </dd>
              </dl>
            </div>
            <div>
              <div class="-mt-px flex divide-x divide-gray-200">
                <div class="w-0 flex-1 flex">
                  <a
                    href="/salesforce-training/digital-adoption-platform/"
                    class="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500"
                  >
                    <span class="ml-3">Read</span>
                  </a>
                </div>
              </div>
            </div>
          </li>

          
        </ul>
      </div>
    </Layout>
  )
}

export default IndexPage
