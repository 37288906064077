/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import { LiveChatLoaderProvider, Intercom } from "react-live-chat-loader"

import Header from "./header"
import Footer from "./footer"
import "./layout.css"

const Layout = ({ children }) => {
  return (
    <>
      <Header />
      <main>
        <div class="px-4 sm:px-6 lg:px-8 bg-white overflow-hidden">
          <div class="lg:max-w-7xl mx-auto">{children}</div>
        </div>
      </main>
      <LiveChatLoaderProvider providerKey="ii6t4rrk" provider="intercom">
        <Intercom color="#FF9C43" />
      </LiveChatLoaderProvider>
      <Footer></Footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
