import React from "react"

const PageTitle = props => {
  return (
    <div class="bg-white">
      <div class="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
        <div class="text-center">
          <p class="text-base font-semibold text-oOrange tracking-wide uppercase">
            {props.preheader}
          </p>
          <h1 class="mt-2 pb-4 text-4xl font-bold text-oGrey sm:text-5xl sm:tracking-tight lg:text-6xl">
            {props.title}
          </h1>
          <p class="max-w-xl mt-5 mx-auto text-xl text-gray-500">
            {props.subtitle}
          </p>
        </div>
      </div>
    </div>
  )
}

export default PageTitle
